import { tailwind as baseStyles } from "@theme-ui/presets"
import merge from "lodash/merge"

export default merge(baseStyles, {
  fonts: {
    body:
      '"Cairo", Rubik, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    heading: '"Cairo", Rubik, sans-serif',
    monospace: "Menlo, monospace",
  },
  colors: {
    heading: "#333",
    header: "#222",
    light: "#f5f5f5",
    footer: "#111",
    primary: "#7d6741",
    lightHeading: "#f9f9f9",
    text: "#777",
    darkGray: "#333",
  },
  forms: {
    input: {
      border: 0,
      borderRadius: 0,
      p: 15,
      mb: 10,
      bg: "#f5f5f5",
      "&:focus": {
        borderColor: "primary",
        outline: "none",
      },
    },
    textarea: {
      border: 0,
      borderRadius: 0,
      p: 15,
      mb: 10,
      bg: "#f5f5f5",
      minHeight: 150,
      "&:focus": {
        borderColor: "primary",
        outline: "none",
      },
    },
  },
  cards: {
    service: {},
    primary: {
      boxShadow: "0 2px 15px 0px rgba(0,0,0,0.07)",
    },
  },
  styles: {
    a: {
      fontSize: "1em",
    },
    p: {
      fontSize: "1em",
    },
    h1: {
      fontSize: ["2.5em"],
      color: "heading",
    },
    h2: {
      fontSize: ["1.8em"],
      color: "heading",
    },
    h3: {
      color: "heading",
    },
    h4: {
      fontSize: "1.3195em",
      color: "heading",
    },
    h5: {
      color: "heading",
    },
    h6: {
      color: "heading",
    },
  },
})
