// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-about-us-layout-js": () => import("./../../../src/layouts/about-us-layout.js" /* webpackChunkName: "component---src-layouts-about-us-layout-js" */),
  "component---src-layouts-author-layout-js": () => import("./../../../src/layouts/author-layout.js" /* webpackChunkName: "component---src-layouts-author-layout-js" */),
  "component---src-layouts-blog-layout-js": () => import("./../../../src/layouts/blog-layout.js" /* webpackChunkName: "component---src-layouts-blog-layout-js" */),
  "component---src-layouts-blog-post-layout-js": () => import("./../../../src/layouts/blog-post-layout.js" /* webpackChunkName: "component---src-layouts-blog-post-layout-js" */),
  "component---src-layouts-category-layout-js": () => import("./../../../src/layouts/category-layout.js" /* webpackChunkName: "component---src-layouts-category-layout-js" */),
  "component---src-layouts-contact-us-layout-js": () => import("./../../../src/layouts/contact-us-layout.js" /* webpackChunkName: "component---src-layouts-contact-us-layout-js" */),
  "component---src-layouts-home-layout-js": () => import("./../../../src/layouts/home-layout.js" /* webpackChunkName: "component---src-layouts-home-layout-js" */),
  "component---src-layouts-project-layout-js": () => import("./../../../src/layouts/project-layout.js" /* webpackChunkName: "component---src-layouts-project-layout-js" */),
  "component---src-layouts-projects-layout-js": () => import("./../../../src/layouts/projects-layout.js" /* webpackChunkName: "component---src-layouts-projects-layout-js" */),
  "component---src-layouts-services-layout-js": () => import("./../../../src/layouts/services-layout.js" /* webpackChunkName: "component---src-layouts-services-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

